<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="$t('ChangeOrder')"
    v-on:submit="save"
    content-class="pl-0 pr-0 mb-2 max-400"
    :hide-submit="error || !children || children.length == 0"
  >
    <template v-slot:content>
      <v-list v-if="children && children.length > 0" class="pt-0 pb-0">
        <draggable
          v-model="temp_children"
          @start="drag = true"
          @end="drag = false"
          tag="div"
        >
          <div
            v-for="(child, index) in temp_children"
            :key="child.id"
            class="sort-item"
          >
            <v-list-item>
              <v-list-item-avatar>
                <v-img
                  :src="$imagehelpers.childAvatar(child.avatar, 300)"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="global-list-header mb-0">{{
                  child.name | capitalize
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="index < temp_children.length - 1"
              :key="index"
            ></v-divider>
          </div>
        </draggable>
      </v-list>
      <div v-else class="text-center pb-5">{{ $t("NoChildren") }}</div>
      <error-box :error="error"></error-box>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
      "ChangeOrder": "Change order",
      "OrderUpdated": "Order updated!",
      "NoChildren": "There is no children to select!"
    },
    "sv": {
      "ChangeOrder": "Ändra ordning",
      "OrderUpdated": "Ordningen sparades!",
      "NoChildren": "Det finns inga barn att välja!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "diary_sort_children_dialog",
  computed: {
    ...mapState({
      children: (state) => state.diary.children.list,
    }),
  },
  components: {
    draggable,
  },
  data: () => ({
    is_open: false,
    error: null,
    is_saving: false,
    temp_children: [],
  }),
  created: function () {
    this.temp_children = [...this.children];
  },
  methods: {
    ...mapActions({
      updateChildrenOrder: "diary/children/updateChildrenOrder",
    }),
    open() {
      this.is_open = true;
    },
    save() {
      var self = this;
      self.is_saving = true;

      var ids = [];

      for (var i = 0; i < this.temp_children.length; i++) {
        ids.push(this.temp_children[i].id);
      }

      self
        .updateChildrenOrder(ids)
        .then(function () {
          self.is_saving = false;
          self.dialog = false;
          self.$successNoty(self.$t("OrderUpdated"));
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
          self.is_saving = false;
        });
    },
  },
  watch: {},
};
</script>

<style scoped>
.sort-item {
  cursor: move;
}

.sort-item:hover {
  background-color: #f6f6f6 !important;
}
</style>